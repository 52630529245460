import { states } from '../components/commonComponents/StatePicker';
import { userDaSubProfessions } from '../constants';

export const PROFESSION_FILTER = (allProfessions) => ({
  key: 'profession',
  label: 'Profession Type',
  options: [{ code: 'all', name: 'All' }, ...allProfessions],
});

export const SUB_PROFESSION_FILTER = {
  key: 'subProfession',
  label: 'Sub-Profession Type',
  options: [{ code: 'all', name: 'All' }, ...userDaSubProfessions],
};

export const STATUS_FILTER = {
  key: 'status',
  label: 'Status',
  options: [
    { code: 'all', name: 'All' },
    { code: 'paused', name: 'Paused' },
    { code: 'restricted', name: 'Restricted' },
    { code: 'verified', name: 'Verified' },
    { code: 'blocked', name: 'Blocked' },
  ],
};

export const CREDENTIAL_TYPE_FILTER = {
  key: 'credentialType',
  label: 'Credential Type',
  options: [
    { code: 'all', name: 'All' },
    { code: 'state_license', name: 'State License' },
    { code: 'da_certificate', name: 'DA Certificate' },
    { code: 'radiology', name: 'Radiology' },
    { code: 'cpr/bls', name: 'CPR/BLS' },
  ],
};

export const ACCOUNT_STATUS_FILTER = {
  key: 'accountStatus',
  label: 'User Status',
  options: [
    { code: 'all', name: 'All' },
    { code: 'verified', name: 'Verified' },
    { code: 'blocked', name: 'Blocked' },
    { code: 'paused', name: 'Paused' },
    { code: 'restricted', name: 'Restricted' },
    { code: 'removed', name: 'Removed' },
  ],
};

export const GOV_ID_UPLOADED_FILTER = {
  key: 'govIdUploaded',
  label: 'Gov. ID Uploaded',
  options: [
    { code: 'all', name: 'All' },
    { code: 'true', name: 'Yes' },
    { code: 'false', name: 'No' },
  ],
};

export const EXPIRES_SOON_FILTER = {
  key: 'expiresSoon',
  label: 'Expiring Soon',
  options: [
    { code: 'all', name: 'All' },
    { code: '15', name: '15 days' },
    { code: '30', name: '30 days' },
  ],
};

export const CONTRACTOR_STATUS_FILTER = {
  key: 'contractorStatus',
  label: 'Contractor Status',
  options: [
    { code: 'all', name: 'All' },
    { code: 'new', name: 'New' },
    { code: 'verified', name: 'Verified' },
    { code: 'restricted', name: 'Restricted' },
  ],
};

export const EMPLOYEE_STATUS_FILTER = {
  key: 'employeeStatus',
  label: 'Employee Status',
  options: [
    { code: 'all', name: 'All' },
    { code: 'new', name: 'New' },
    { code: 'verified', name: 'Verified' },
    { code: 'restricted', name: 'Restricted' },
  ],
};

export const DSO_MANAGER_FILTER = (dsoManagerList) => ({
  key: 'dsoManager',
  label: 'DSO Manager',
  options: [
    { code: 'all', name: 'All' },
    { code: 'unassigned', name: 'None' },
    ...dsoManagerList.map((el) => ({
      code: el.id,
      name: `${el.first_name}
	${el.last_name}`,
    })),
  ],
})

export const DSO_RAM_MANAGER_FILTER = (dsoRAMManagerList) => ({
  key: 'dsoRamIds',
  label: 'DSO RAM Manager',
  options: [
    { code: 'all', name: 'All' },
    { code: 'unassigned', name: 'None' },
    ...dsoRAMManagerList.map((el) => ({
      code: el.id,
      name: `${el.first_name} ${el.last_name}`,
    })),
  ],
});

export const STATES_FILTER = {
  key: 'state',
  label: 'State',
  options: [
    { code: 'all', name: 'All' },
    ...states.map((state) => ({ code: state.abbreviation, name: state.abbreviation })),
  ],
};

export const WORKER_TYPE_FILTER = {
  key: 'workerClassification',
  label: 'Worker Type',
  options: [
    { code: 'all', name: 'All' },
    { code: 'unselected', name: 'Unselected' },
    { code: 'employee', name: 'Employee' },
    { code: 'contractor', name: 'Contractor' },
  ],
};

export const WORKER_TYPE_REQUEST_FILTER = {
  key: 'workerClassificationRequest',
  label: 'Worker Type Request Status',
  options: [
    { code: 'all', name: 'All' },
    { code: 'requested', name: 'Requested' },
    { code: 'confirmed', name: 'Confirmed' },
    { code: 'declined', name: 'Declined' },
  ],
};

export const RAM_VERIFIED_FILTER = {
  key: 'ramVerified',
  label: 'RAM Verified',
  options: [
    { code: 'all', name: 'All' },
    { code: 'unverified', name: 'Unverified' },
    { code: 'fake', name: 'Fake' },
    { code: 'verified', name: 'Verified' },
  ],
};

export const USER_TYPE_FILTER = {
  key: 'user_type',
  label: 'User Type',
  options: [
    { code: 'all', name: 'All' },
    { code: 'HYG', name: 'HYG' },
    { code: 'DNT', name: 'DNT' },
  ],
}

export const REGISTRATION_STATUS_FILTER = {
  key: 'registrationStatus',
  label: 'Registration Status',
  options: [
    { code: 'all', name: 'All' },
    { code: 'short-account', name: 'Short Account' },
    { code: 'incomplete-account', name: 'Incomplete Account ' },
    { code: 'complete-account', name: 'Complete Account' },
    { code: 'non-compliant-account', name: 'Non-Compliant Account' },
    { code: 'verified-account', name: 'Verified Account' },
    { code: 'activated-account', name: 'Activated Account' },
  ],
};