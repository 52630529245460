import dateFormat from 'dateformat';
import phoneFormatter from 'phone-formatter';
import React from 'react';
import { RAM_VERIFIED_STATUS } from '../../enums/AccountStatus';

const style = {
  tableText: {
    fontWeight: 'bold',
    fontSize: '13px',
    fontFamily: 'Nunito',
    color: '#435963',
  },
  columnHeader: {
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: 'Nunito',
    color: '#435963',
    width: '112px',
  },
};

export function deviceFormatter(column) {
  if (column === 'ios') {
    return <img data-tag="allowRowEvents" src={require('../../images/ios.png')} alt="ios" style={{ width: '20px' }} />;
  }
  if (column === 'android') {
    return (
      <img data-tag="allowRowEvents" src={require('../../images/android.png')} alt="android" style={{ width: '20px' }} />
    );
  }
  return (
    <img data-tag="allowRowEvents" src={require('../../images/desktop.png')} alt="desktop" style={{ width: '20px' }} />
  );
}

function dateFormatter(column) {
  return <p data-tag="allowRowEvents">{column ? dateFormat(column, 'ddd, mmmm dS, yyyy') : 'N/A'}</p>;
}

function emailFormatter(column) {
  return <p data-tag="allowRowEvents" style={{ overflowX: 'auto' }}>{column}</p>;
}

function customPhoneFormatter(column) {
  return (
    <span data-tag="allowRowEvents">
      {!column ? "N/A" : phoneFormatter?.format(column, '(NNN) NNN-NNNN') || 'N/A'}
    </span>
  );
}
export const columns = [
  {
    dataField: 'device_type',
    text: 'Device',
    formatter: deviceFormatter,
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'created_at',
    text: 'Created',
    formatter: dateFormatter,
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'last_time_sign_in',
    text: 'Last sign in',
    formatter: dateFormatter,
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'info_filled',
    text: 'Completed Date',
    formatter: (_, user) => (user.info_filled ? dateFormatter(user.info_filled) : ' - '),
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },

  {
    dataField: 'email_id',
    text: 'Email',
    formatter: emailFormatter,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'office_name',
    text: 'Office',
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'dsoName',
    text: 'DSO name',
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'first_name',
    text: 'First Name',
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'last_name',
    text: 'Last Name',
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'contact_number',
    text: 'Contact',
    formatter: customPhoneFormatter,
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'address[0].city',
    text: 'City',
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'address[0].state',
    text: 'State',
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
  {
    dataField: 'address[0].postal_code',
    text: 'Zip',
    sort: true,
    style: style.tableText,
    headerStyle: style.columnHeader,
  },
];


export const userListColumn = () => [
  {
    id: 'device_type',
    selector: (row) => row.device_type,
    name: 'Device',
    format: (row) => deviceFormatter(row.device_type),
    style: style.tableText,
    sortable: true,
    sortField: 'device_type',
    width: "80px"
  },
  {
    id: 'account_standing',
    selector: (row) => row.account_standing,
    name: 'Acct Standing',
    format: (row) => row.account_standing,
    style: style.tableText,
    sortable: true,
    sortField: 'account_standing',
  },
  {
    id: 'user_type',
    selector: (row) => row.user_type,
    name: 'User Type',
    format: (row) => row.user_type,
    style: style.tableText,
    sortable: true,
    sortField: 'user_type',
  },
  {
    id: 'created_at',
    name: 'Created',
    selector: (row) => row.created_at,
    cell: (row) => dateFormatter(row.created_at),
    style: style.tableText,
    sortable: true,
    sortField: 'created_at',
  },
  {
    id: 'last_time_sign_in',
    name: 'Last sign in',
    selector: (row) => row.last_time_sign_in,
    cell: (row) => dateFormatter(row.last_time_sign_in),
    style: style.tableText,
    sortable: true,
    sortField: 'last_time_sign_in',
  },
  {
    id: 'info_filled',
    name: 'Completed Date',
    selector: (row) => row.info_filled,
    cell: (row) => row.info_filled ? dateFormatter(row.info_filled) : " - ",
    style: style.tableText,
    sortable: true,
    sortField: 'info_filled',
  },
  {
    id: 'email_id',
    name: 'Email',
    selector: (row) => row.email_id,
    format: (row) => emailFormatter(row.email_id),
    style: style.tableText,
    minWidth: '200px',
  },
  {
    id: 'office_name',
    selector: (row) => row.office_name,
    name: 'Office',
    style: style.tableText,
    format: (row) => row.office_name || "-",
    minWidth: '140px',
  },
  {
    id: 'dsoName',
    selector: (row) => row.dsoName,
    name: 'DSO name',
    style: style.tableText,
    format: (row) => row.dsoName || "-",
  },
  {
    id: 'first_name',
    selector: (row) => row.first_name,
    name: 'First Name',
    sortable: true,
    sortField: 'first_name',
    style: style.tableText,
  },
  {
    id: 'last_name',
    selector: (row) => row.last_name,
    name: 'Last Name',
    sortable: true,
    sortField: 'last_name',
    style: style.tableText,
  },
  {
    id: 'contact_number',
    selector: (row) => row.contact_number,
    name: 'Contact',
    cell: (row) => customPhoneFormatter(row.contact_number),
    sortable: true,
    sortField: 'contact_number',
    style: style.tableText,
    minWidth: '140px'
  },
  {
    id: 'address[0].city',
    selector: (row) => row.address?.[0]?.city,
    cell: (row) => row.address?.[0]?.city,
    name: 'City',
    sortable: true,
    sortField: 'address[0].city',
    style: style.tableText,
  },
  {
    id: 'address[0].state',
    selector: (row) => row.address?.[0]?.state,
    name: 'State',
    sortable: true,
    sortField: 'address[0].state',
    style: style.tableText,
  },
  {
    id: 'address[0].postal_code',
    selector: (row) => row.address?.[0]?.postal_code,
    name: 'Zip',
    sortable: true,
    sortField: 'address[0].postal_code',
    style: style.tableText,
  },
  {
    id: 'average_rating',
    selector: (row) => row.average_rating,
    name: 'Average Rating',
    format: (row) => (row.average_rating ? row.average_rating.toFixed(1) : '-'),
    sortable: true,
    sortField: 'average_rating',
    style: style.tableText,
  },
];

export const userListConditionalRowStyles = [
  {
    when: row => row?.office_info?.ramVerified !== RAM_VERIFIED_STATUS.VERIFIED,
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
  {
    when: row => row?.office_info?.ramVerified === RAM_VERIFIED_STATUS.VERIFIED,
    style: {
      backgroundColor: '#C1F5B9',
    },
  },
]

export const hygListConditionalRowStyles = [
  {
    when: (row) => 
      row.account_standing === 'unverified' ||
      row.professional_info?.government_id?.status !== 'verified',
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
  {
    when: row => 
      row.account_standing === 'verified' &&
      row.professional_info?.government_id?.status === 'verified',
    style: {
      backgroundColor: '#C1F5B9',
    },
  },
  {
    when: row => row.account_standing === 'paused',
    style: {
      backgroundColor: '#FFDB9A',
    },
  },
  {
    when: row => row.account_standing === 'invalid',
    style: {
      backgroundColor: '#F3C9EC',
    },
  },
  {
    when: row => row.account_standing === 'blocked' || row.account_standing === 'restricted',
    style: {
      backgroundColor: '#F8B5B5',
    },
  },
]

export const unfinishedUserListConditionalRowStyles = [
  {
    when: row => row,
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
]