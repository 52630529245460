import { useSelector } from 'react-redux';
import { CustomSelect } from '../components/CustomSelect';
import { HasRequiredCredential } from '../components/HasRequiredCredential';
import { HYG_FILTERS } from '../utils';
import { REGISTRATION_STATUS_FILTER } from '../../../../utils/filters';

export const CustomFilters = ({ filter, handleFilterChange }) => {
  const allProfessions = useSelector((state) => state.jobs.allProfessions);
  
  return(
    <>
      {HYG_FILTERS(allProfessions)?.map(({ key, label, options }) => (
        <CustomSelect
          key={key}
          id={key}
          label={label}
          options={options}
          value={filter[key]}
          onChange={handleFilterChange}
        />
      ))}

      <HasRequiredCredential filter={filter} handleFilterChange={handleFilterChange} />

      <CustomSelect
        key={REGISTRATION_STATUS_FILTER.key}
        id={REGISTRATION_STATUS_FILTER.key}
        label={REGISTRATION_STATUS_FILTER.label}
        options={REGISTRATION_STATUS_FILTER.options}
        value={filter[REGISTRATION_STATUS_FILTER.key]}
        onChange={handleFilterChange}
      />
    </>
  )
}