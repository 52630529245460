import React from 'react';
import dateFormat from 'dateformat';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import '../../../themes/JobDetailsBox.scss'

export default function JobDetailsBox ({ job_detail: jobDetail, onEdit, onCancel, openMessages, theOneWhoPosted }) {
  const {
    professionName,
    subProfession,
    transaction_id: transactionId,
    messages,
    confirmAttendance,
    confirmPerformedBy,
    author,
    urgentShift,
    repost,
    workerClassification,
    autoFill,
    autofillOptions,
    isAutoFilled,
    lunch_break_time: lunchBreakTime,
    is_lunch_break_paid: isLunchBreakPaid,
    rate,
    original_rate: originalRate,
    regularAndOvertimeHours,
    local_start_time: localStartTime,
    local_end_time: localEndTime,
    local_date: localDate,
    job_filled_datetime: jobFilledDatetime,
    createdAt,
    id: jobDetailId,
    procedures,
    specialty,
    isAssistedHygiene,
    profession,
  } = jobDetail || {}

  const jobProfessionName = UserSubProfession.getName(subProfession) ?? professionName;

  const renderCancelButton = () => {
    if (jobDetail.is_cancelled === false) {
      return (
        <div>
          <button
            className="btn btn-danger mb-4 mr-2"
            type="button"
            style={{
              width: 150,
              borderRadius: 10,
              marginTop: '12px',
            }}
            onClick={onCancel}
          >
            Cancel Job
          </button>
        </div>
      );
    }
    return <div />;
  };

  const renderEditButton = () => (
    <button
      onClick={onEdit}
      className="btn btn-info mb-2"
      type="button"
      style={{ width: 150, marginTop: '12px', borderRadius: 10 }}
    >
      Edit
    </button>
  );

  const renderDynamicLink = () => (
    <div className="dynamic-links">
      <p>
        <b>Link to job for the office :</b>
      </p>
      <p>
        {`${process.env.REACT_APP_JOB_DNT_LINK}${jobDetailId}`}
        <button
          className="btn btn-info mr-1 ml-3"
          onClick={() =>
            navigator.clipboard.writeText(`${process.env.REACT_APP_JOB_DNT_LINK}${jobDetailId}`)
          }
        >
          Copy
        </button>
      </p>
      <p>
        <b>Link to open shift for Professional :</b>
      </p>
      <p>
        {`${process.env.REACT_APP_JOB_HYG_LINK}${jobDetailId}`}
        <button
          className="btn btn-info mr-1 ml-3"
          onClick={() =>
            navigator.clipboard.writeText(`${process.env.REACT_APP_JOB_HYG_LINK}${jobDetailId}`)
          }
        >
          Copy
        </button>
      </p>
    </div>
  );

  const preferType = {
    'more_experience': 'Most Experience',
    'firs_available': '1st Available',
    'closer_to_me': 'Closer To Me',
  }[jobDetail.prefer_type] ?? '-';

  return (
    <div className="card mt-4 mb-4 job-details-box">
      <div className="card-body container">
        <h3 className="card-title mt-2 title">
          Job Details
        </h3>
        <div className="row">
          <p className="card-text row-title">
            Job ID
          </p>
          <p className="card-text">{transactionId || ''}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Created
          </p>
          <p className="card-text">
            {dateFormat(createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT') || ''}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Filled
          </p>
          <p className="card-text">
            {jobFilledDatetime
              ? dateFormat(jobFilledDatetime, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
              : '-'}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Profession
          </p>
          <p className="card-text">{jobProfessionName || '-'}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Skills
          </p>
          <p className="card-text" style={{ textTransform: 'capitalize' }}>
            {specialty?.split('_')?.[1] || '-'}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Shift Procedures
          </p>
          <p className="card-text" style={{ textTransform: 'capitalize', maxWidth: '50%', textAlign: 'right' }}>
            {(procedures ?? []).map((procedure) => procedure.replace(/^procedure_/g, '')).join(', ')}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Date
          </p>
          <p className="card-text">{localDate || ''}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Time
          </p>
          <p className="card-text">
            {`${localStartTime} - ${localEndTime}`}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Regular hours
          </p>
          <p className="card-text">
            {regularAndOvertimeHours?.regular
              ? `${regularAndOvertimeHours?.regular} hr${
                regularAndOvertimeHours?.regular > 1 ? 's' : ''
              }`
              : '-'}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Overtime hours (1.5 * rate)
          </p>
          <p className="card-text">
            {regularAndOvertimeHours?.overtime
              ? `${regularAndOvertimeHours?.overtime} hr${
                regularAndOvertimeHours?.overtime > 1 ? 's' : ''
              }`
              : '-'}
          </p>
        </div>

        {jobDetail.original_rate && (
          <div className="row">
            <p className="card-text row-title">
              Original Rate
            </p>
            <p className="card-text">{originalRate.toFixed(2) || ''}</p>
          </div>
        )}

        <div className="row">
          <p className="card-text row-title">
            Rate
          </p>
          <p className="card-text">
            {Number.isInteger(rate) ? rate?.toFixed(2) : rate}
          </p>
        </div>

        <div className="row">
          <p className="card-text row-title">
            Break Paid
          </p>
          <p className="card-text">{isLunchBreakPaid ? 'Yes' : 'No' || ''}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Break Time
          </p>
          <p className="card-text">{`${lunchBreakTime}` || ''}</p>
        </div>
        {profession === UserProfession.RDH && 
          <div className="row">
            <p className="card-text row-title">
              Assisted Hygiene Available
            </p>
            <p className="card-text">
              {isAssistedHygiene ? 'Yes' : 'No'}
            </p>
          </div>
        }
        <div className="row">
          <p className="card-text row-title">
            Auto-Confirm (Favorites)
          </p>
          <p className="card-text">
            {autofillOptions?.favorite ? 'On' : 'Off' || ''}{' '}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Auto-Confirm (Highly-Rated)
          </p>
          <p className="card-text">
            {autofillOptions?.highlyRated ? 'On' : 'Off' || ''}{' '}
          </p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Auto-Confirm (All Eligible)
          </p>
          <p className="card-text">
            {autoFill ? 'On' : 'Off' || ''}{' '}
            {isAutoFilled ? '(Auto-filled)' : ''}
          </p>
        </div>

        <div className="row">
          <p className="card-text row-title">
            Worker Type
          </p>
          <p className="card-text">{workerClassification || '-'}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Dentist Prefer
          </p>
          <p className="card-text">{`${preferType}` || ''}</p>
        </div>

        <div className="row">
          <p className="card-text row-title">
            Reposted
          </p>
          <p className="card-text">{repost ? 'Yes' : 'No'}</p>
        </div>

        <div className="row">
          <p className="card-text row-title">
            Urgent Shift
          </p>
          <p className="card-text">{urgentShift ? 'Yes' : 'No'}</p>
        </div>

        <div className="row">
          <p className="card-text row-title">
            Extra Pay
          </p>
          <p className="card-text">
            {urgentShift?.bonusPay?.toFixed(2) ?? '-'}
          </p>
        </div>

        <div className="row">
          <p className="card-text row-title">
            Posted By
          </p>
          <p className="card-text">{theOneWhoPosted(author)}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Confirmed By
          </p>
          <p className="card-text">{theOneWhoPosted(confirmPerformedBy)}</p>
        </div>
        <div className="row">
          <p className="card-text row-title">
            Professional Confirmation
          </p>
          <p className="card-text">
            {confirmAttendance?.confirmationDate
              ? dateFormat(
                confirmAttendance?.confirmationDate,
                'dddd, mmmm dS, yyyy, h:MM:ss TT',
              )
              : '-'}
          </p>
        </div>
      </div>

      {renderDynamicLink()}

      {messages?.length !== 0 && (
        <div>
          <button
            className="btn btn-success mb-4 mr-2"
            type="button"
            style={{
              width: 150,
              borderRadius: 10,
              marginTop: '12px',
            }}
            onClick={() => openMessages()}
          >
            Open Job Messages
          </button>
        </div>
      )}

      {renderEditButton()}
      {renderCancelButton()}
    </div>
  );
};
