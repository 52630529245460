import tempMee from '../apis/tempMee';
import {
  GET_ALL_UNVERIFIED_LICENSES_SUCCESS,
  GET_ALL_UNVERIFIED_LICENSES_FAILURE,
  GET_ALL_UNVERIFIED_LICENSES_REQUEST,
  CREDENTIAL_ACTION,
  SHOW_MESSAGE,
  EDUCATION_ACTION,
} from './actionTypes';

import { getAuthHeaders } from './authActions';
import { UserType } from '../enums/UserType';
import { UserProfession } from '../enums/UserProfession';

export const fetchLicenses = (state) => async (dispatch, getState) => {
  let params = {};

  if (state) {
    params = {
      page: state.page || 1,
      sortBy: state.sortField?.split('.')?.[1] || '',
      sortDirection: state.sortOrder?.toUpperCase() || '',
      limit: 15,
      status: state.status || '',
      state: state.state || '',
      profession: state.profession || '',
      subProfession: state.subProfession || '',
      credential_type: state.credentialType || '',
      account_status: state.accountStatus || '',
      gov_uploaded: state.govIdUploaded || '',
      gov_status: state.govIdUploaded || '',
      expiresSoon: state.expiresSoon || '',
      contractor_status: state.contractorStatus || '',
      employee_status: state.employeeStatus || '',
    };
  } else {
    params = {
      page: 1,
    };
  }

  dispatch({
    type: GET_ALL_UNVERIFIED_LICENSES_REQUEST,
    payload: params,
  });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/licenses', {
      params,
      headers,
    });
    dispatch({ type: GET_ALL_UNVERIFIED_LICENSES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_UNVERIFIED_LICENSES_FAILURE, payload: err });
  }
};

/**
 * To get educations of professions
 *
 * @param {string[] | UserProfession[]} professions
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchRequestedEducations = (
  professions = [UserProfession.RDH, UserProfession.DA]
) => async (dispatch) => {
  dispatch({
    type: EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();

    const responses = await Promise.all(
      professions.map((profession) => tempMee.get('credential-service/credentials', {
        headers,
        params: {
          profession,
          userType: UserType.HYG
        }
      }))
    );

    const educations = responses.reduce((acc, response) => ({
      ...acc.data,
      ...response.data
    }))

    dispatch({ type: EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_SUCCESS, payload: educations });
  } catch (err) {
    dispatch({ type: EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_FAILURE, payload: err });
  }
}

export const getUserEducation = ({ userType, profession } = {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDUCATION_ACTION.GET_USER_EDUCATION_REQUEST });

    const user = getState().users.currentUser;
    const type = userType || user?.user_type;
    const userProfession = profession || user?.licenseTypes?.[0] || 'RDH';

    const headers = await getAuthHeaders();
    const response = await tempMee.get(
      `credential-service/credentials`, {
        headers,
        params: {
          profession: userProfession,
          userType: type
        }
      });

    dispatch({
      type: EDUCATION_ACTION.GET_USER_EDUCATION_SUCCESS,
      payload: response.data[type === 'DNT' ? 'DNT' : userProfession],
    });
  } catch (err) {
    dispatch({ type: EDUCATION_ACTION.GET_USER_EDUCATION_FAILURE, payload: err });
  }
};

export const updateUserEducation = ({
  years_of_experience,
  school,
  graduationDate,
  education,
  insurances,
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_REQUEST });

    const headers = await getAuthHeaders();
    const userId = getState().users.currentUser.id;

    await tempMee.patch(`/admin/users/${userId}`,
      { years_of_experience, school, graduationDate },
      { headers },
    );

    const response = await tempMee.patch(`admin/users/${userId}/specialtiesAndProcedures`,
      { education, insurances },
      { headers },
    );

    dispatch({
      type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User education have been updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_FAILURE, payload: err });
  }
};

export const fetchStateCredentials = () => async (dispatch) => {
  dispatch({
    type: CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('users/state-credentials', {
      headers,
    });

    dispatch({ type: CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_FAILURE, payload: err });
  }
};

export const createCredentialEntryType = (data, licId) => async (dispatch, getState) => {
  dispatch({
    type: CREDENTIAL_ACTION.POST_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST,
  });
  try {
    const headers = await getAuthHeaders();
    const currUserId = getState().users.currentUser.id;

    await tempMee.post(`/admin/users/${currUserId}/licenses/${licId}/credentials`, data, {
      headers,
    });
  } catch (err) {
    dispatch({ type: CREDENTIAL_ACTION.POST_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE, payload: err });
  }
};

export const updateCredentialEntryType = (data, licId, credentialId) => async (dispatch, getState) => {
  dispatch({
    type: CREDENTIAL_ACTION.PATCH_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST,
  });
  try {
    const headers = await getAuthHeaders();
    const currUserId = getState().users.currentUser.id;

    await tempMee.patch(`/admin/users/${currUserId}/licenses/${licId}/credentials/${credentialId}`, data, {
      headers,
    });
  } catch (err) {
    dispatch({ type: CREDENTIAL_ACTION.PATCH_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE, payload: err });
  }
};

export const uploadCredential = (image, data, licId, credentialId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREDENTIAL_ACTION.UPLOAD_STATE_CREDENTIALS_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const form = new FormData();
    form.append('image', image);
    const headers = await getAuthHeaders();

    if (data) {
      const credentialUploadData = await tempMee.post(`/admin/users/${currUserId}/licenses/${licId}/credentials-upload`, data, {
        headers,
      });

      credentialId = credentialUploadData.data.id;
    }

    const response = await tempMee.patch(`/admin/users/${currUserId}/licenses/${licId}/credentials-upload/${credentialId}`, form, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Credential is uploaded',
      },
    });

    return response.data;
  } catch (error) {
    dispatch({ type: CREDENTIAL_ACTION.UPLOAD_STATE_CREDENTIALS_FAILURE, payload: error });
  }
};

export const deleteCredentialUpload = (licId, credentialId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.delete(`/admin/users/${currUserId}/licenses/${licId}/credentials-upload/${credentialId}`, {
      headers: {
        ...headers,
      },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Credential is deleted',
      },
    });
  } catch (error) {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_FAILURE, payload: error });
  }
};

export const deleteCredentialFile = (licId, credentialId, fileId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.delete(`/admin/users/${currUserId}/licenses/${licId}/credentials-upload/${credentialId}/${fileId}`, {
      headers: {
        ...headers,
      },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Image is deleted',
      },
    });
  } catch (error) {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_FAILURE, payload: error });
  }
};
